document.addEventListener('DOMContentLoaded', function(event) {

	'use strict';

	/**
	 * Mobile: Open / close hamburger menu
	 */
	var hamburger 	= document.getElementById('toggle-nav-menu');
	var navigation = document.getElementById('nav-container-mobile');
	navigation.classList.remove('nav-container-mobile--no-js');
	navigation.querySelector('.nav-top-area').classList.remove('no-js');
	hamburger.classList.remove('no-js');
	hamburger.addEventListener('click', function () {
		document.body.classList.toggle('nav--active');
		if (navigation.classList.contains('nav-container-mobile--visible')) {
			hamburger.classList.remove('nav-open');
			navigation.classList.remove('nav-container-mobile--visible');
			hamburger.setAttribute('aria-expanded', 'false');
		} else {
			hamburger.classList.add('nav-open');
			navigation.classList.add('nav-container-mobile--visible');
			hamburger.setAttribute('aria-expanded', 'true');
		}
	});
});
