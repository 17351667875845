document.addEventListener('DOMContentLoaded', function(event) {

	'use strict';

	/**
	 * Mobile & Desktop both: Open / close submenu
	 */
	var has_submenus = document.getElementsByClassName('nav-main__item--has-children');
	var index = 0;
	Array.prototype.forEach.call(has_submenus, function() {
		var submenu 					= has_submenus[index].querySelector('.nav-main__sub-menu');
		var submenu_parent 			= submenu.parentNode;
		var submenu_button 			= document.createElement('button');
		submenu_button.innerHTML 	= '<span class="screen-reader-text">Avaa tai sulje alavalikko</span><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#3475A0" d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>';
		submenu_button.classList.add('toggle-submenu');
		submenu_button.setAttribute('aria-haspopup', 'true');
		submenu_button.setAttribute('aria-expanded', 'false');
		submenu_parent.insertBefore(submenu_button, submenu);

		var link_next_to_button				= submenu_parent.querySelector('a');

		submenu_button.addEventListener('click', function(e) {
			if ( submenu.classList.contains('nav-main__sub-menu--visible') || submenu_button.classList.contains('toggle-submenu--opened') ) {
				submenu.classList.remove('nav-main__sub-menu--visible');
				submenu_button.classList.remove('toggle-submenu--opened');
				link_next_to_button.classList.remove('active-boi'); // NEW
				submenu_button.setAttribute('aria-expanded', 'false');
			} else {
				// Desktop: close other open submenus
				if ( window.innerWidth > 991 ) {
					var i = 0;
					Array.prototype.forEach.call(has_submenus, function() {
						var other_button 					= has_submenus[i].querySelector('.toggle-submenu');
						var other_submenu 				= has_submenus[i].querySelector('.nav-main__sub-menu');
						var other_link_next_to_button = other_submenu.parentNode.querySelector('a');
						other_submenu.classList.remove('nav-main__sub-menu--visible');
						other_button.classList.remove('toggle-submenu--opened');
						other_button.setAttribute('aria-expanded', 'false');
						other_link_next_to_button.classList.remove('active-boi'); // NEW
						i++;
					});
				}
				submenu.classList.add('nav-main__sub-menu--visible');
				submenu_button.classList.add('toggle-submenu--opened');
				link_next_to_button.classList.add('active-boi'); // NEW
				submenu_button.setAttribute('aria-expanded', 'true');
			}
		});
		index++;
	});
});
