document.addEventListener('DOMContentLoaded', function(event) {

	'use strict';

	/**
	 *  Desktop: Click anywhere other than the menu --> close menu
	 */
	var has_submenus = document.getElementsByClassName('nav-main__item--has-children');
	function close_menus() {
		document.getElementsByTagName('body')[0].addEventListener('click', function(e) {
			if ( window.innerWidth > 991 && HTMLCollection.prototype.isPrototypeOf(has_submenus)) {
				var x = 0;
				Array.prototype.forEach.call(has_submenus, function() {
					if ( !e.target.classList.contains('toggle-submenu') ) {
						var submenu = has_submenus[x].querySelector('.nav-main__sub-menu');
						var button 	= has_submenus[x].querySelector('.toggle-submenu');
						var link_next_to_button = submenu.parentNode.querySelector('a');
						link_next_to_button.classList.remove('active-boi'); // NEW
						submenu.classList.remove('nav-main__sub-menu--visible');
						button.classList.remove('toggle-submenu--opened');
						button.setAttribute('aria-expanded', 'false');
					}
					x++;
				});
			}
		});
	}
	close_menus();
	window.addEventListener('resize', close_menus);
});
