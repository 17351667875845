// Language menu dropdown

(function(d) {
	'use strict';

	if (!d.addEventListener ||
		!d.querySelector ||
		!'classList' in d.createElement('_')) {

		return;
	}

	var element = d.querySelector('.pot-language-nav');
	var menu = element.querySelector('ul');
	var buttonTexts = JSON.parse(element.getAttribute('data-buttontexts'));

	if (!element || !menu || !buttonTexts) {
		return;
	}

	var button = element.querySelector('button');
	var buttonTitle = button.querySelector('.pot-button-title');
	var buttonTitleFaux = button.querySelector('.pot-button-title-faux');
	var buttonText = buttonTitle.textContent;

	buttonTexts.default = buttonText.trim();
	element.classList.add('pot-language-drop');
	menu.hidden = true;
	button.hidden = false;

	button.addEventListener('click', function() {

		var expanded = this.getAttribute('aria-expanded') === 'true' || false;

		this.setAttribute('aria-expanded', !expanded);
		menu.hidden = !menu.hidden;
		buttonTitle.textContent = expanded ? buttonText.default : buttonTexts.close;
		buttonTitleFaux.classList.toggle('pot-ico-3-arrow-up');

	});

})(document);
